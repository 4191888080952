import NotiStackProvider from "components/NotiStackProvider"
import React from "react"
import Router from "routes"
import ThemeProvider from "themes"

export default function App() {
  return (
    <ThemeProvider>
      <NotiStackProvider>
        <Router />
      </NotiStackProvider>
    </ThemeProvider>
  )
}
