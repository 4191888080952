// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none"
        },
        head: {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.grey[400],
          "&:first-of-type": {
            paddingLeft: theme.spacing(3),
            borderTopLeftRadius: theme.shape.borderRadius
            // boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3),
            borderTopRightRadius: theme.shape.borderRadius
            // boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`
          }
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`
        },
        body: {
          "&:first-of-type": {
            paddingLeft: theme.spacing(3)
          },
          "&:last-of-type": {
            paddingRight: theme.spacing(3)
          }
        }
      }
    }
  }
}
