import { IconButton } from "@mui/material"
import { SnackbarProvider, closeSnackbar } from "notistack"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/material"

export default function NotiStackProvider({ children }) {
  const theme = useTheme()

  return (
    <SnackbarProvider
      autoHideDuration={3000}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      action={(snackbarId) => {
        return (
          <IconButton sx={{ color: theme.palette.common.white }} onClick={() => closeSnackbar(snackbarId)}>
            <CloseIcon />
          </IconButton>
        )
      }}
    >
      {children}
    </SnackbarProvider>
  )
}
