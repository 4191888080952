import { Alert, Box, Button, Dialog, DialogContent, Stack, Typography, styled, useTheme } from "@mui/material"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import Image from "components/Image"
import AndroidBadge from "assets/images/badge/android.png"
import IosBadge from "assets/images/badge/ios.png"
import useClipboard from "hooks/useClipboard"
import { FormProvider, RHFTextField } from "components/hook-form"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"

const DialogTitleStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  gap: theme.spacing(3)
}))

const CardStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(6, 4),
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),
  flex: "1 0 0",
  alignSelf: "stretch",
  backgroundColor: theme.palette.grey[500],
  border: `1px solid ${theme.palette.divider}`
}))

const CardTitleStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  gap: theme.spacing(2)
}))

const CardContentStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(6),
  flex: "1 0 0",
  alignSelf: "stretch"
}))

const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  alignSelf: "stretch"
}))

const BadgeStyle = styled(Typography)(({ theme }) => ({
  display: "flex",
  width: 48,
  height: 48,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1.25),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: 99
}))

export default function DialogSetupMFA({ open, onClose, qrCode, manualEntryKey, onActive, ...other }) {
  const theme = useTheme()
  const [isCopied, handleCopy] = useClipboard()

  const SetupMFASchema = Yup.object().shape({
    code: Yup.string().required("code is required")
  })

  const defaultValues = {
    code: ""
  }

  const methods = useForm({
    resolver: yupResolver(SetupMFASchema),
    defaultValues
  })

  const {
    handleSubmit,
    setError,
    resetField,
    setFocus,
    formState: { errors }
  } = methods

  const onSubmit = async (data) => {
    try {
      const error = await onActive(data.code)
      if (!!error.message) setError("afterSubmit", { message: error.message })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = (event) => {
    resetField("code")
    onClose(event)
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFocus("code")
      })
    }
  }, [setFocus, open])

  return (
    <Dialog open={open} {...other} maxWidth="xl" onClose={handleClose}>
      <DialogContent>
        <DialogTitleStyle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              alignSelf: "stretch"
            }}
          >
            <Typography variant="h4" color="primary">
              ACIS Cyber Resilience Suite Platforms
            </Typography>
            <Typography variant="subtitle1">Set up Two-Factor Authentication</Typography>
          </Box>
        </DialogTitleStyle>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            alignSelf: "stretch"
          }}
        >
          <Typography variant="caption">
            Follow these simple steps to activate two-factor authentication for your account.
          </Typography>

          <CardContainer>
            <CardStyle>
              <CardTitleStyle>
                <BadgeStyle>1</BadgeStyle>

                <Typography variant="caption" textAlign="center">
                  Install the Microsoft Authenticator app on your phone.
                </Typography>
              </CardTitleStyle>

              <CardContentStyle>
                <Image src={AndroidBadge} sx={{ width: 180 }} />
                <Image src={IosBadge} sx={{ width: 180 }} />
              </CardContentStyle>
            </CardStyle>

            <CardStyle>
              <CardTitleStyle>
                <BadgeStyle>2</BadgeStyle>

                <Typography variant="caption" textAlign="center">
                  Scan this QA code with your authenticator app or enter the secret manually.
                </Typography>
              </CardTitleStyle>

              <CardContentStyle>
                <Image src={qrCode} sx={{ width: 180 }} />
              </CardContentStyle>
            </CardStyle>

            <CardStyle>
              <CardTitleStyle>
                <BadgeStyle>3</BadgeStyle>

                <Typography variant="caption" textAlign="center">
                  Write down this secret key to restore you two-factor authentication.
                </Typography>
              </CardTitleStyle>

              <CardContentStyle>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    alignSelf: "stretch"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      height: 72,
                      padding: theme.spacing(2, 3),
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="h4">{manualEntryKey}</Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    color={isCopied ? "success" : "primary"}
                    startIcon={isCopied ? <CheckOutlinedIcon /> : <ContentCopyOutlinedIcon />}
                    onClick={() => handleCopy(manualEntryKey)}
                  >
                    {isCopied ? `Copied` : `Copy`}
                  </Button>
                </Box>

                <Typography variant="caption" color="grey.300" textAlign="center">
                  If your phone got lost or erased, you will need this key to restore your two-factor authentication.
                </Typography>
              </CardContentStyle>
            </CardStyle>

            <CardStyle>
              <CardTitleStyle>
                <BadgeStyle>4</BadgeStyle>

                <Typography variant="caption" textAlign="center">
                  Enter the 6 digit code displayed on your authenticator app below.
                </Typography>
              </CardTitleStyle>

              <CardContentStyle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={1}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "stretch",
                        gap: 2
                      }}
                    >
                      <RHFTextField
                        name="code"
                        autoComplete="off"
                        size="medium"
                        inputProps={{
                          sx: {
                            textAlign: "center",
                            fontSize: 26,
                            fontWeight: 700,
                            bgcolor: theme.palette.common.white
                          },
                          maxLength: 6
                        }}
                      />

                      <Button variant="contained" type="submit" color="primary">
                        Active
                      </Button>
                    </Box>
                  </Stack>
                </FormProvider>

                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.success.main, textAlign: "center", fontWeight: 600 }}
                >
                  Your two-factor authentication has been activated successfully.
                </Typography>
              </CardContentStyle>
            </CardStyle>
          </CardContainer>

          <Button
            variant="contained"
            sx={{ bgcolor: theme.palette.grey[500], color: theme.palette.common.black }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
