import { AppBar, Box, Stack, Toolbar, Typography, styled } from "@mui/material"
import Logo from "components/Logo"
import { HEADER } from "config"
import MainAccount from "layouts/main/MainAccount"
import { useNavigate } from "react-router-dom"

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  zIndex: theme.zIndex.appBar + 1,
  height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  backgroundColor: theme.palette.background.default,
  border: `solid 1px ${theme.palette.divider}`,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: theme.palette.primary.main
}))

export default function MainHeader() {
  const navigate = useNavigate()

  return (
    <RootStyle>
      <Toolbar disableGutters sx={{ minHeight: "100% !important", px: { lg: 5 } }}>
        <Box sx={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => navigate("/")}>
          <Logo disabledLink sx={{ mr: 2 }} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography component="h3" sx={{ fontSize: "37px", fontWeight: 700, lineHeight: 64 / 48 }}>
              ACIS-CR3
            </Typography>
            <Typography variant="subtitle2">ACIS Resilience Readiness Review</Typography>
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <MainAccount />
        </Stack>
      </Toolbar>
    </RootStyle>
  )
}
