import { CircularProgress, alpha, styled } from "@mui/material"

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: alpha(theme.palette.common.black, 0.5)
}))

export default function LoadingScreen() {
  return (
    <RootStyle>
      <CircularProgress />
    </RootStyle>
  )
}
