import PropTypes from "prop-types"
import { useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import useAuth from "hooks/useAuth"
import LoadingScreen from "components/LoadingScreen"
import Login from "pages/auth/Login"

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth()
  const { pathname } = useLocation()
  const [requestedLocation, setRequestedLocation] = useState(null)

  if (!isInitialized) {
    return <LoadingScreen />
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname)
    }
    return <Login />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation} />
  }

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node
}
