import { Box, Stack, styled } from "@mui/material"
import { Outlet, useLocation } from "react-router-dom"
import AdminFooter from "layouts/admin/AdminFooter"
import AdminHeader from "layouts/admin/AdminHeader"
import { HEADER } from "config"

const MainStyle = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  minHeight: "calc(100vh - 48px)",
  backgroundColor: theme.palette.grey[500]
}))

export default function AdminLayout() {
  const { pathname } = useLocation()

  return (
    <Stack sx={{ minHeight: 1 }}>
      <AdminHeader />

      <MainStyle>
        <Outlet />
      </MainStyle>

      <Box sx={{ flexGrow: 1 }} />

      <AdminFooter isAssessment={!pathname.includes("/assessment")} />
    </Stack>
  )
}
