import { jwtDecode } from "jwt-decode"
import axios from "./axios"

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }
  const decoded = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("accessToken", accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    sessionStorage.removeItem("accessToken")
    delete axios.defaults.headers.common.Authorization
  }
}

export { isValidToken, setSession }
