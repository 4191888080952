import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { MenuItem, TextField } from "@mui/material"

export default function RHFSelect({ name, children, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          helperText={error?.message}
          SelectProps={{
            multiple: false,
            value: field.value
          }}
          {...other}
        >
          {children || <MenuItem></MenuItem>}
        </TextField>
      )}
    />
  )
}

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string
}
