// SETUP COLORS
const PRIMARY = {
  lighter: "#E6EFF6",
  light: "#6BC1FF",
  main: "#005C9E",
  dark: "#005C9E",
  darker: "#033458"
}
const SECONDARY = {
  lighter: "#D6E4FF",
  light: "#84A9FF",
  main: "#3366FF",
  dark: "#1939B7",
  darker: "#091A7A"
}
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A"
}
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D"
}
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01"
}
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E"
}
const GREY = {
  0: "#EFF0F1",
  100: "#DCE1E5",
  200: "#979FA6",
  300: "#99A2AD",
  400: "#F4F6F8",
  500: "#F9FAFB",
  600: "#EAECF0"
}
const COMMON = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY
}

const palette = {
  ...COMMON,
  divider: "#E6EFF6",
  mode: "light"
}

export default palette
