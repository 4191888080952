import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { TextField } from "@mui/material"

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          error={!!error}
          helperText={error?.message}
          autoFocus
          {...other}
        />
      )}
    />
  )
}

RHFTextField.propTypes = {
  name: PropTypes.string
}
