// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        containedSuccess: {
          color: theme.palette.common.white
        }
      }
    }
  }
}
