// 📚LIBRARY
import PropTypes from "prop-types"
import { Navigate } from "react-router-dom"

// 📎HOOK
import useAuth from "hooks/useAuth"

// 🧩COMPONENT

// 🗳️ASSETS

// ⏬PROJECT IMPORT

// 🖌️STYLE

// ==============================|| GUEST GUARD ||============================== //

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Navigate to="/main" />
  }

  return <>{children}</>
}

GuestGuard.propTypes = {
  children: PropTypes.node
}
