import PropTypes from "prop-types"
import { useFormContext, Controller } from "react-hook-form"
import { IconButton, TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import ClearIcon from "@mui/icons-material/Clear"

export default function RHFDatePicker({ name, clearable, disabled, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          inputFormat="DD/MM/YYYY"
          componentsProps={{ actionBar: { actions: [clearable ? "clear" : null] } }}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              {...other}
              error={!!error}
              helperText={error?.message}
              inputProps={{
                ...params.inputProps,
                placeholder: other.placeholder
              }}
              fullWidth
            />
            // <div style={{ position: "relative", display: "inline-block" }}>
            //   <IconButton
            //     size="medium"
            //     sx={{ position: "absolute", top: ".5rem", margin: "auto", right: "2rem" }}
            //     onClick={() => field.onChange(null)}
            //   >
            //     <ClearIcon sx={{ width: 24, height: 24 }} />
            //   </IconButton>
            // </div>
          )}
        />
      )}
    />
  )
}

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool
}
