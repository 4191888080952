import React from "react"
import { Typography, styled } from "@mui/material"

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0, 8),
  height: "48px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}))

export default function AdminFooter({ isAssessment = true }) {
  return (
    isAssessment && (
      <RootStyle>
        <Typography component="p" variant="caption" sx={{ fontSize: 14 }}>
          Copyright© 2023. All rights reserved.
        </Typography>
        <Typography component="p" variant="caption" sx={{ fontSize: 14 }}>
          ACIS Cyber Resilience Suite (ACIS-ResSuite)
        </Typography>
      </RootStyle>
    )
  )
}
