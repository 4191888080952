import { Card, Stack, Typography, Box } from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import Page from "components/Page"
import Image from "components/Image"
import Overlay3 from "assets/images/background/2f186302fdb938b2cc30bd48ab1e1e6a.png"
import Overlay4 from "assets/images/background/81f0f97b4a97f7a6699431d81115c5e2.png"
import Logo from "components/Logo"
import LoginForm from "./components/LoginForm"

const RootStyle = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "row",
  minHeight: "100vh",
  position: "relative",
  "&::before": {
    content: '""',
    width: "100%",
    height: "100%",
    zIndex: -1,
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${Overlay4})`,
    opacity: 0.1
  }
}))

const ContentStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 1),
  [theme.breakpoints.up("md")]: {
    padding: 0,
    boxShadow: "none",
    overflow: "unset",
    backgroundColor: theme.palette.common.white
  }
}))

const CardStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(6),
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.common.white,
  boxShadow: `0px 16px 64px 0px ${alpha(theme.palette.common.white, 0.08)}`
}))

export default function Login() {
  return (
    <Page title="Login">
      <RootStyle component="main">
        <Stack px={{ xs: 2, md: 8 }} ml="auto" mr="auto" mt="auto" mb="auto">
          <CardStyle>
            <Logo />

            <ContentStyle>
              <Stack direction="column" gap="16px" mb="36px" textAlign="center">
                <Typography variant="h6" gutterBottom>
                  ACIS Cyber Resilience Suite Platforms
                </Typography>

                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                  Self-Assessment Package
                </Typography>
              </Stack>

              <LoginForm />
            </ContentStyle>
          </CardStyle>
        </Stack>

        <Stack flexGrow={1} position="relative">
          <Image src={Overlay3} sx={{ width: "auto", height: "100vh" }} />
        </Stack>
      </RootStyle>
    </Page>
  )
}
