import { Controller, useFormContext } from "react-hook-form"

import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

// ----------------------------------------------------------------------

export default function RHFAutocomplete({ name, label, helperText, hiddenLabel, placeholder, ...other }) {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          id={`rhf-autocomplete-${name}`}
          onChange={(_, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={ref}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password"
              }}
            />
          )}
          {...other}
        />
      )}
    />
  )
}
