//

import Table from "./Table"
import Button from "./Button"

// ----------------------------------------------------------------------

export default function ComponentsOverride(theme) {
  return Object.assign(Button(theme), Table(theme))
}
