import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Box, Button, Dialog, DialogContent, Link, Stack, Typography, styled, useTheme } from "@mui/material"
import { FormProvider, RHFTextField } from "components/hook-form"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import * as Yup from "yup"

const DialogTitleStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  gap: theme.spacing(3)
}))

const CardStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(4),
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),
  flex: "1 0 0",
  alignSelf: "stretch",
  backgroundColor: theme.palette.grey[500],
  border: `1px solid ${theme.palette.divider}`
}))

const CardTitleStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: "stretch",
  gap: theme.spacing(2)
}))

const CardContentStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  flex: "1 0 0",
  alignSelf: "stretch"
}))

const CardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: theme.spacing(3),
  alignSelf: "stretch"
}))

export default function DialogVerifyMFA({ open, onClose, onVerify, ...other }) {
  const theme = useTheme()

  const VerifyMFASchema = Yup.object().shape({
    code: Yup.string().required("code is required")
  })

  const defaultValues = {
    code: ""
  }

  const methods = useForm({
    resolver: yupResolver(VerifyMFASchema),
    defaultValues
  })

  const {
    handleSubmit,
    setError,
    resetField,
    setFocus,
    formState: { errors }
  } = methods

  const onSubmit = async (data) => {
    try {
      const error = await onVerify(data.code)
      if (!!error.message) setError("afterSubmit", { message: error.message })
    } catch (error) {
      console.error(error.message)
    }
  }

  const handleClose = (event) => {
    resetField("code")
    onClose(event)
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setFocus("code")
      })
    }
  }, [setFocus, open])

  return (
    <Dialog open={open} {...other} maxWidth="xs" onClose={handleClose}>
      <DialogContent>
        <DialogTitleStyle>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              alignSelf: "stretch"
            }}
          >
            <Typography variant="h5" color="primary">
              ACIS Cyber Resilience Suite Platforms
            </Typography>
            <Typography variant="subtitle1">Two-Factor Authentication</Typography>
          </Box>
        </DialogTitleStyle>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            alignSelf: "stretch"
          }}
        >
          <CardContainer>
            <CardStyle>
              <CardTitleStyle>
                <Typography variant="body2" textAlign="center">
                  Two-Factor Authentication Code
                </Typography>
              </CardTitleStyle>

              <CardContentStyle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={1}>
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 3,
                        alignSelf: "stretch"
                      }}
                    >
                      <RHFTextField
                        name="code"
                        autoComplete="off"
                        size="medium"
                        inputProps={{
                          sx: {
                            textAlign: "center",
                            fontSize: 26,
                            fontWeight: 700,
                            bgcolor: theme.palette.common.white
                          },
                          maxLength: 6
                        }}
                      />

                      <Typography variant="caption" color="grey.300" textAlign="center">
                        Enter the code from the two-factor app on your mobile device. If you’ve lost your device. You
                        may enter one of your secret key.
                      </Typography>

                      <Button variant="contained" color="primary" type="submit">
                        Verify Code
                      </Button>
                    </Box>
                  </Stack>
                </FormProvider>
                <Box>
                  <Typography variant="body2" textAlign="center">
                    Lost your phone ?
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    Use a{" "}
                    <Link variant="body2" underline="none" component={RouterLink} to="#">
                      secret key
                    </Link>{" "}
                    or{" "}
                    <Link variant="body2" underline="none" component={RouterLink} to="#">
                      contact administrator
                    </Link>
                  </Typography>
                </Box>
              </CardContentStyle>
            </CardStyle>
          </CardContainer>

          <Button
            variant="contained"
            sx={{ bgcolor: theme.palette.grey[500], color: theme.palette.common.black }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
